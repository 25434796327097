
jQuery(document).ready(function ($) {
  // //instagram
  // $("#instagram-feed").juicer()

  // Mobile Navigation
  $(".mobile-menu-icon").on('click', function (e) {
    e.preventDefault();
    $("#navbar").toggleClass("active");
  });

  // // Youtube embed extract
  $('.video-switch a').on('click', function (e) {
    e.preventDefault();
    $('.feature-video').attr('src', "https://www.youtube.com/embed/" + $(this).attr('data-videoid'));
    $('.feature-title').html($(this).attr('data-title'));
  })
});